.drawer {
  height: 100vh;
  position: fixed;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, .5);
  background: white;
  z-index: 9999;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  .header {
    flex-shrink: 0;
  }
  .drawerScroll {
    flex: 1;
    max-height: 100%;
    height: 100%;
    overflow: auto;
  }
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  z-index: 9998;
}
.disableSelect {
  user-select: none;
}