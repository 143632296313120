@import '../../css/global/colors.scss';

$borderRadius: 4px;

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  .inputWrapper {
    display: flex;
    position: relative !important;
    align-items: center;
    width: 100%;
    text-align: left;
    border-radius: $borderRadius;
    input, select, textarea {
      position: relative;
      z-index: 2;
      height: calc(100% - 2px);
      width: calc(100% - 2px);
      box-sizing: border-box;
      padding: 0 15px;
      border: 0;
      margin: 0 auto;
      background: none;
      font-size: 1rem;
      font-weight: 400 !important;
      border-radius: $borderRadius;
      appearance: none;
      -webkit-appearance: none;
      font-family: inherit;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: $grey;
      }
    }
    textarea {
      resize: none;
    }
    .fieldset {
      display: block;
      position: absolute;
      top: -7px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 0 10px;
      border: 1px solid $grey;
      border-radius: $borderRadius;
      background-color: $white;
      cursor: text;
      .label {
        overflow: hidden;
        position: absolute;
        height: 1.1rem;
        font-size: 1rem;
        line-height: 1rem;
        transform-origin: left center;
        color: $grey;
        transition: color 0s cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        transform: translate(5px, -14px) scale(0.75);
        z-index: 3;
        font-weight: 400;
        &.fullWidth {
          width: calc(130% - 30px);
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &.centered {
          white-space: nowrap;
          text-overflow: ellipsis;
          width: calc(100% - 30px);
          color: $grey;
          z-index: 1;
        }
      }
      .legend {
        display: block;
        width: auto;
        height: 14px;
        padding: 0;
        visibility: hidden;
        text-align: left;
        font-size: 1rem;
        max-width: 0.01px;
        transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
	      margin: 0;
        &.visible {
          max-width: 1000px;
          transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
        }
        &.smaller {
          font-size: 0.9rem;
        }
      }
    }
    .icon {
      position: absolute;
      z-index: 3;
    }
    &:hover {
      .fieldset {
        border-color: $black;
        .label {
          color: $black;
          &.centered {
            color: $grey;
          }
        }
      }
    }
    &.focused {
      .fieldset {
        border-color: $darkTeal;
        .label {
          color: $darkTeal;
        }
      }
      .legend {
        color: $darkTeal;
      }
    }
    &.disabled {
      .fieldset {
        border-color: $grey;
      }
      *:not(svg, path) {
        color: $grey !important;
      }
    }
    &.noAnimation {
      .fieldset {
        .label {
          transition: color 0s cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        }
        .legend {
          transition: none;
          &.visible {
            transition: none;
          }
        }
      }
    }
    &.error {
      .label {
        color: $red !important;
        &.centered {
          color: $grey !important;
        }
      }
      .fieldset {
        border-color: $red;
      }
    }
    &.small {
      min-height: 36px;
      input, select, textarea {
        font-size: 0.95rem;
        line-height: 2.25rem;
      }
      .label {
        &.centered {
          transform: translate(5px, 3px) scale(1);
        }
      }
    }
    &.medium {
      min-height: 48px;
      line-height: 3rem;
      top: -1px;
      .fieldset .label {
        &.centered {
          transform: translate(5px, 8px) scale(1);
        }
      }
    }
    &.textarea {
      height: 100px;
      line-height: 1rem;
      padding: 12px 0;
      box-sizing: border-box;
    }
    &.underline {
      .fieldset {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        padding: 0;
        .label {
          &.fullWidth {
            width: 130%;
          }
          &.centered {
            width: 100%;
          }
          transform: translate(0, -14px) scale(0.75);
        }
      }
      input, select, textarea {
        padding: 0;
      }
    }
    &.small.underline .fieldset label.centered {
      transform: translate(0, 3px) scale(1);
    }
    &.medium.underline .fieldset label.centered {
      transform: translate(0, 8px) scale(1);
    }
    &.withLeftIcon {
      .icon {
        left: 15px;
      }
      input, select, textarea {
        padding: 0 15px 0 40px !important;
      }
      &.small .label.centered {
        transform: translate(30px, 3px) scale(1);
      }
      &.medium .fieldset .label.centered {
        transform: translate(30px, 8px) scale(1);
      }
      &.underline {
        .icon {
          left: 0;
        }
        input, select {
          padding-left: 25px !important;
        }
      }
      &.medium.underline .fieldset label.centered {
        transform: translate(25px, 8px) scale(1);
      }
      &.small.underline .fieldset label.centered {
        transform: translate(25px, 3px) scale(1);
      }
    }
    &.withRightIcon {
      .icon {
        right: 17px;
      }
      input, select {
        padding-right: 43px;
      }
      .label {
        &.centered {
          width: calc(100% - 47px);
        }
      }
      &.underline {
        .icon {
          right: 0;
        }
        input, select {
          padding-right: 15px;
        }
        .label {
          &.centered {
            width: calc(100% - 20px);
          }
        }
      }
    }
  }
  .errorMessage {
    width: 100%;
    margin-top: 4px;
  }
  .message {
    width: 100%;
    margin-top: 4px;
    color: $grey;
  }
}
