@import '../../css/global/colors.scss';
@import '../../css/global/typography.scss';
@import '../../css/mixins/responsiveness.scss';

.button,
.link {
  position: relative;
  overflow: hidden;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
  background-position: center;
  transition: background 0.5s, color 0.5s;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  // Sizes
  &.small {
    padding: 0 16px;
    height: 36px;
    font-size: $fontSm;
    .icon {
      margin-right: 12px;
    }
    @include below(sm) {
      padding: 0 0.5rem;
    }
  }
  &.medium {
    padding: 0 20px;
    height: 48px;
    font-size: $fontMd;
    .icon {
      margin-right: 12px;
    }
    @include below(sm) {
      padding: 0 0.5rem;
    }
  }
  &.fluid {
    width: 100%;
    padding: 0 20px;
    height: 48px;
    box-sizing: border-box;
    font-size: 1rem;
    @include below(sm) {
      padding: 0 0.5rem;
    }
  }
  &.smallFluid {
    width: 100%;
    padding: 0 16px;
    height: 36px;
    font-size: $fontSm;
  }

  // Types
  &.primary {
    background-color: $darkerTeal;
    color: $white;
    border: 1px solid $darkerTeal;
    &:hover,
    &:focus {
      background: $darkerTeal radial-gradient(circle, transparent 1%, $darkerTeal 1%) center / 15000%;
      background-color: $darkTeal;
      border: 1px solid $darkTeal;
    }
    &:active {
      background-color: $darkTeal;
      background-size: 100%;
      transition: background 0s;
    }
    &.loading {
      &:hover,
      &:focus,
      &:active,
      &:visited {
        background: $darkTeal;
        border: 1px solid $darkTeal;
        cursor: default;
        user-select: none;
        transition: none;
      }
    }
  }
  &.pink {
    background-color: $warmPink;
    color: $white;
    border: 1px solid $warmPink;
    &:hover,
    &:focus {
      background: $warmPink radial-gradient(circle, transparent 1%, $warmPink 1%) center / 15000%;
      background-color: lighten($warmPink, 10%);
      border: 1px solid lighten($warmPink, 10%);
    }
    &:active {
      background-color: lighten($warmPink, 10%);
      background-size: 100%;
      transition: background 0s;
    }
    &.loading {
      &:hover,
      &:focus,
      &:active,
      &:visited {
        background: $warmPink;
        border: 1px solid $warmPink;
        cursor: default;
        user-select: none;
        transition: none;
      }
    }
  }
  &.grey {
    background-color: $darkGrey;
    color: $white;
    border: 1px solid $darkGrey;
    &:hover,
    &:focus {
      background: $warmPink radial-gradient(circle, transparent 1%, $darkGrey 1%) center / 15000%;
      background-color: lighten($darkGrey, 10%);
      border: 1px solid lighten($darkGrey, 10%);
    }
    &:active {
      background-color: lighten($darkGrey, 10%);
      background-size: 100%;
      transition: background 0s;
    }
    &.loading {
      &:hover,
      &:focus,
      &:active,
      &:visited {
        background: $darkGrey;
        border: 1px solid $darkGrey;
        cursor: default;
        user-select: none;
        transition: none;
      }
    }
  }
  &.red {
    background-color: $red;
    color: $white;
    border: 1px solid $red;
    &:hover,
    &:focus {
      background: $red radial-gradient(circle, transparent 1%, $red 1%) center / 15000%;
      background-color: lighten($red, 10%);
      border: 1px solid lighten($red, 10%);
    }
    &:active {
      background-color: lighten($red, 10%);
      background-size: 100%;
      transition: background 0s;
    }
    &.loading {
      &:hover,
      &:focus,
      &:active,
      &:visited {
        background: $red;
        border: 1px solid $red;
        cursor: default;
        user-select: none;
        transition: none;
      }
    }
  }
  &.primary,
  &.pink,
  &.red {
    &.disabled {
      background-color: $grey;
      border: 1px solid $grey;
      transition: none;
      &:hover,
      &:focus,
      &:active,
      &:visited {
        background: $grey;
        border: 1px solid $grey;
        cursor: default;
        user-select: none;
        transition: none;
      }
    }
  }
  &.secondary {
    border: 1px solid $darkerTeal;
    color: $darkerTeal;
    background-color: $white;
    &:hover,
    &:focus {
      background: $lighterTeal radial-gradient(circle, transparent 1%, $lighterTeal 1%) center / 15000%;
      background-color: $lightTeal;
    }
    &:active {
      background-color: $lightTeal;
      background-size: 100%;
      transition: background 0s, color 0s;
    }
    &.disabled {
      border: 1px solid $grey;
      color: $grey;
      &:hover,
      &:focus,
      &:active,
      &:visited {
        transition: none;
        color: $grey;
        cursor: default;
        user-select: none;
        background-color: $white;
      }
    }
    &.loading {
      &:hover,
      &:focus,
      &:active,
      &:visited {
        transition: none;
        border: 1px solid $darkerTeal;
        cursor: default;
        user-select: none;
      }
    }
  }
  &.text {
    background: none;
    border: none;
    color: $darkTeal;
    border: 1px solid transparent;
    &.small {
      padding: 0 8px;
      .icon {
        margin-right: 6px;
      }
    }
    &:hover,
    &:focus {
      background-color: $darkTeal;
      border: 1px solid $darkTeal;
      color: $white;
    }
    &:active {
      background-color: transparent;
      background-size: 100%;
      transition: background 0s, color 0s;
    }
    &.disabled {
      color: $grey;
      &:hover {
        cursor: default;
        background: none;
        border: 1px solid transparent;
      }
    }
  }
  &.text.pink {
    color: $warmPink;
    &:hover,
    &:focus {
      background-color: $warmPink;
      border: 1px solid $warmPink;
      color: $white;
    }
  }
  .buttonSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.link {
  text-decoration: none;
  display: inline-block;
  line-height: 48px;
  &.small {
    line-height: 36px;
  }
}
