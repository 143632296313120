@import '../../../../css/global/index.scss';

p {
  margin: 0;
}
.wrapper {
  margin: 5rem 0;
}
.title {
  margin: 0 0 0 1.5rem;
  color: $darkerGrey;
  font-weight: $semibold;
}