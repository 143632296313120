@import '../../../../../css/global/colors.scss';

.button {
  position: relative;
  transition: all 200ms ease-out;
  background: none !important;
  border: 0;
  height: 100%;
  background-color: $warmPink !important;
  color: $white !important;
  padding: 0 1.25rem;
  border-radius: 0;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    transition: all 200ms ease-in;
    background-color: rgba($warmPink, 0.9);
  }
  &:focus {
    outline: none;
  }
  .icon {
    font-size: 1rem;
  }
  .number {
    position: absolute;
    background-color: $white;
    border-radius: 50%;
    color: $warmPink;
    width: 14px;
    top: 3px;
    right: 11px;
    font-size: 0.625rem;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
}