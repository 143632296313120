@import '../../../css/mixins/_responsiveness.scss';

.wrapper {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: flex-start;
  &.right {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
  @include below(xs) {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
}