@import '../../css/global/colors.scss';

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.8) !important;
}
.scrollArea {
  background-color: $whiteGrey;
}