@import '../../css/global/colors.scss';
@import '../../css/global/typography.scss';
@import '../../css/mixins/responsiveness.scss';
@import '../../css/global/z-index.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: $modalOverlayZIndex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .65);
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 95%;
  height: auto;
  max-height: 95%;
  z-index: $modalContentZIndex;
  background-color: $whiteGrey;
  border-radius: 4px;

  @include above(sm) {
    max-height: 90%;
    max-width: 700px;
    width: 100%;
  }

  .modalHeader {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    .closeButton {
      margin: 1rem;
      font-size: 1.75rem;
      width: 1em;
      height: 1em;
      background: none !important;
      border: 0 !important;
      color: black !important;
      padding: 0;
      &:focus,
      &:hover {
        color: $grey !important;
        background: none !important;
      }
      &.disabled {
        opacity: 0;
        visibility: none;
        user-select: none;
      }
    }
  }

  .modalContent {
    box-sizing: border-box;
    padding: 0 3rem;
    margin-bottom: 2.5rem;
    height: auto;
    flex-grow: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @include below(sm) {
      padding: 0 1rem;
    }
  }
}