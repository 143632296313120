@import '../../../../css/global/index.scss';

p {
  margin: 0;
}
.wrapper {
  padding: 0 1.25rem;
  margin: 2rem 0;
  .price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    p {
      color: $darkGrey;
      padding-left: 1rem;
      font-size: 1rem;
    }
    p + p {
      padding: 0 1rem 0 0;
    }
  }
  .total {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $blueGrey;
    padding-top: 1rem;
    margin-bottom: 1rem;
    p {
      color: $darkGrey;
      font-size: 1.25rem;
      font-weight: $bold;
      padding-left: 1rem;
    }
    p + p {
      padding: 0 1rem 0 0;
    }
  }
  &.empty {
    p {
      color: $darkGrey;
    }
  }
}