@import '../../css/global/colors.scss';
@import '../../css/global/borders.scss';
@import '../../css/mixins/responsiveness';
@import '../../css/global/z-index.scss';

.wrapper {
  background-color: $white;
  position: absolute;
  width: 310px;
  padding: 1.25rem;
  box-sizing: border-box;
  border-radius: $mediumBorderRadius;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: $tooltipZIndex;
  &.sticky {
    position: fixed;
  }
  @include below(xs) {
    left: 1.5rem !important;
    width: calc(100vw - 3rem) !important;
  }
  .arrow {
    position: absolute;
    top: -9px;
    width: 1rem;
    height: 1rem;
    background-color: $white;
    transform: rotate(45deg);
    z-index: $tooltipArrowZIndex;
    &.arrowOnTop {
      border-left: 1px solid $grey;
      border-top: 1px solid $grey;
    }
  }
}