.userItem {
  display: flex;
  .fullname {
    font-size: 1rem !important;
  }
}
.userItem + .userItem {
  margin-top: 1.5rem;
}
.submitButtonWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}