@import '../../../../css/global/colors.scss';
@import '../../../../css/global/typography.scss';
@import '../../../../css/mixins/responsiveness.scss';

.wrapper {
  display: flex;
  align-items: center;
}
.menuWrapper {
  position: fixed;
  top: var(--headerHeight);
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: $hintOfGreen;
  overflow: auto;
  padding: 1.5rem 0;
  .subItemWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    .subItemTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 63px;
      width: 100%;
      box-sizing: border-box;
      .subItemIcon {
        font-size: 1.625rem;
        margin-right: 0.25rem;
        transition: all var(--animationTransitionMs) ease-out;
      }
    }
    &.opened {
      .subItemTop {
        .subItemIcon {
          transform: rotate(180deg);
          transition: all 150ms ease-in;
        }
      }
    }
  }
  .itemWrapper {
    width: 100%;
    .itemTop {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 63px;
      box-sizing: border-box;
      padding: 0 20px;
      &:after {
        content: '';
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 20px;
        right: 20px;
        background: rgba(0, 0, 0, .14);
      }
      .itemTitle {
        font-size: 1.25rem;
        color: $darkerGrey;
        font-weight: bold;
      }
      .itemIcon {
        font-size: 1.625rem;
        margin-right: 0.25rem;
        transition: all var(--animationTransitionMs) ease-out;
      }
    }
    &.opened {
      .itemTop {
        background-color: $lightTeal;
        border-bottom: 0;
        &:before {
          content: '';
          height: 1px;
          position: absolute;
          top: -1px;
          left: 20px;
          right: 20px;
          background-color: $hintOfGreen;
          z-index: 1;
        }
        .itemTitle {
          color: $darkerTeal;
        }
        .itemIcon {
          color: $darkerTeal;
          transform: rotate(180deg);
          transition: all 150ms ease-in;
        }
        &:after {
          background: transparent;
        }
      }
    }
    .subItemList {
      padding: 0 !important;
      margin: 0 !important;
      margin-left: 16px;
      margin-bottom: 16px;
      list-style: none !important;
      .subListItem {
        padding: 1rem 0;
        .subItemLink {
          text-decoration: none;
        }
      }
    }
    .itemList {
      padding: 0 !important;
      margin: 0 !important;
      list-style: none !important;
      .listItem {
        position: relative;
        display: flex;
        align-items: center;
        height: 63px;
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px;
        .itemLink {
          font-size: $fontLg;
          text-decoration: none;
          color: $darkerGrey !important;
          background: none !important;
          font-size: 1rem r !important;
          border: 0 !important;
        }
        &:after {
          content: '';
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 20px;
          right: 20px;
          background: rgba(0, 0, 0, .14);
        }
        &.noHeight {
          height: auto;
        }
      }
    }
  }
}
.authButtons {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  padding: 0 20px;
  box-sizing: border-box;
  button + button {
    margin-top: 1rem;
  }
}