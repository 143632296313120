@import '../../../../../../css/global/colors.scss';
@import '../../../../../../css/mixins/responsiveness.scss';

.wrapper {
  display: flex;
  align-items: center;
  margin-left: 20px;
  .checkbox {
    display: none;
  }
  .checkboxLabel {
    display: block;
    width: 28px;
    cursor: pointer;
    margin: 0 !important;
    padding: 0 !important;
    &:before, &:after {
      display: none;
    }
    div {
      position: relative;
      top: 0;
      height: 4px;
      background-color: $darkerTeal;
      margin-bottom: 3px;
      transition: 0.3s ease transform, 0.3s ease top, 0.3s ease width,
        0.3s ease right;
      border-radius: 2px;
      &:first-child {
        transform-origin: 0;
      }
      &:last-child {
        margin-bottom: 0;
        transform-origin: 30px;
      }
      &:nth-child(2) {
        right: 0;
      }
    }
  }
  .checkbox:checked + label div:first-child {
    top: -3px;
    right: -5px;
    transform: rotateZ(45deg);
  }
  .checkbox:checked + label div:last-child {
    top: 4px;
    right: 5px;
    transform: rotateZ(45deg);
  }
  .checkbox:checked + label div:nth-child(2) {
    width: 30px;
    top: 0;
    right: 0px;
    transform: rotateZ(-45deg);
  }
}