@import '../../../../../css/global/colors.scss';

.menu {
  position: fixed;
  background-color: $hintOfGreen;
  padding: 0 !important;
  margin: 0 !important;
  z-index: 103;
  list-style: none !important;
  border: 1px solid rgba($black, 0.1);
  .menuItem {
    padding: 1rem 1.25rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .icon {
      color: $grey;
      font-size: 0.875rem;
      padding-right: 0.75rem;
    }
    &:hover {
      cursor: pointer;
      background-color: rgba($darkerTeal, 0.1);
    }
  }
}
.mobileMenu {
  position: relative;
  margin-top: 2.5rem;
  padding: 0 20px;
  box-sizing: border-box;
  select {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    user-select: none;
    appearance: none;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 1.125rem;
    }
    .content {
      font-size: 1rem;
      word-break: break-all;
      text-align: center;
      margin: 0 0.5rem;
    }
  }
}