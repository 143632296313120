@import '../../../../css/global/index.scss';

p {
  margin: 0;
}
.item {
  width: calc(100% - 2.5rem);
  border-radius: $mediumBorderRadius;
  margin: 1rem auto;
  padding: 1.25rem 1.375rem;
  box-sizing: border-box;
  background-color: $white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, .2);
  .itemTop {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.375rem;
    .itemTopTitle {
      font-size: 1rem;
      font-weight: $semibold;
      color: $darkerTeal;
      &.disabled {
        color: $grey;
      }
    }
    .itemTopDeleteButton {
      font-size: 1rem !important;
      color: $darkerTeal !important;
      cursor: pointer;
      background: none !important;
      border: 0 !important;
      padding: 0 !important;
      &:focus, &:hover {
        outline: none;
        background: none !important;
      }
    }
  }
  .itemAddButton {
    font-size: 1rem;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .price {
    display: flex;
    margin-top: 0.75rem;
    .priceOld {
      margin-right: 0.5rem;
      text-decoration: line-through;
      color: $darkGrey;
      font-size: 1.025rem;
    }
    &.recommended {
      margin-top: 0;
      align-items: center;
      .priceOld {
        font-size: 0.75rem;
      }
    }
    .priceNew {
      color: $darkerTeal;
      font-weight: $semibold;
    }
  }
  .recommendedProductPriceWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    color: $darkerTeal;
    font-weight: $semibold;
  }
}