$fontFamily: Helvetica Neue, Helvetica, Arial,sans-serif !important;

$light: 300;
$normal: 400;
$semibold: 500;
$bold: 700;

$fontSm: 0.75rem;
$fontMd: 0.875rem;
$fontDefault: 1rem;
$fontLg: 1.25rem;
$fontXl: 1.5rem;
$fontXxl: 2.25rem;

.main-title {
  font-weight: $light;
  margin: 2rem 0 1.5rem;
  font-size: $fontXxl;
  line-height: 2.75rem;
  color: $black;
}

.sub-title {
  font-weight: $light;
  margin: 1rem 0;
  font-size: $fontXl;
  line-height: 2.75rem;
  color: $black;
}

.font-medium {
  font-size: $fontDefault;
  line-height: 1.625;
  color: var(--darkerGrey);
}