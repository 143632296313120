@import '../../../../../css/global/colors.scss';
@import '../../../../../css/mixins/responsiveness.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  .categories {
    display: flex;
  }
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
}