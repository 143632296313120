@import '../../../../../css/global/colors.scss';

.menu {
  position: fixed;
  background-color: $hintOfGreen;
  border: 1px solid rgba($black, 0.1);
  margin: 0 !important;
  z-index: 103;
  list-style: none !important;
  padding: 0.25rem 0 !important;
  .menuItem {
    padding: 0 2rem;
    box-sizing: border-box;
    .menuLink {
      display: flex;
      border: 0;
      background: none;
      text-decoration: none;
      padding: 0.75rem 2rem 0.75rem 0;
      border-bottom: 1px solid rgba($black, 0.1);
      color: $darkerGrey;
      font-size: 1rem;
      &:hover {
        color: $darkerTeal;
        cursor: pointer;
      }
      &:focus,
      &:active {
        outline: none;
        color: $darkerTeal;
      }
    }
    &:last-child {
      .menuLink {
        border-bottom: 0;
      }
    }
  }
}