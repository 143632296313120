@import '../../../../../css/global/colors.scss';
@import '../../../../../css/mixins/responsiveness.scss';

.logoLink {
  font-size: 0;
  .logoIcon {
    width: 134px;
    height: 16px;
    fill: $darkerTeal;
    transition: fill var(--animationTransitionMs) ease-out;
    &.small {
      height: 20px;
      width: 34px;
    }
  }
  &.headerExtended {
    @include below(xs) {
      .logoIcon {
        fill: $white;
        transition: fill var(--animationTransitionMs) ease-in;
      }
    }
  }
}