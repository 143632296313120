@import '../../../../css/global/index.scss';

p {
  margin: 0;
}
.logoWrapper {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  .logo {
    max-width: 45%;
    height: auto;
  }
}
.wrapper {
  width: calc(100% - 2.5rem);
  margin: 1.5rem auto;
  padding: 1.5rem;
  box-sizing: border-box;
  border-radius: $mediumBorderRadius;
  background-color: $darkerTeal;
  .wrapperTitle {
    font-size: 1.25rem;
    font-weight: $semibold;
    color: $white;
    margin: 0 0 0.5rem;
  }
  .wrapperText {
    color: $white;
    line-height: 1.4;
  }
}