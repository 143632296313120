@import '../../css/global/index.scss';

.modal {
  background-color: $white;
}

.form {
  .title {
    margin-bottom: 1.5rem;
    line-height: 1.4;
    font-weight: 400;
    font-size: 1.25rem;
  }
  .formItem {
    display: flex;
    margin-bottom: 1rem;
  }
  .formMultiItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .formItem {
      width: 49%;
    }
  }
  .formItemIcon {
    cursor: pointer;
    color: $darkerTeal;
  }
  .break {
    height: 1.25rem;
    width: 100%;
  }
  .formNavigationButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    button + button {
      margin-left: 1rem;
    }
    .buttonIcon {
      margin-right: 0.5rem;
    }
  }
  .resetPasswordButton {
    width: 100%;
    display: flex;
    font-size: 1rem;
    justify-content: flex-end;
    margin: 1.125rem 0;
    background: none !important;
    color: black;
    border: none;
    &:focus,
    &:hover {
      text-decoration: underline;
      background: none !important;
      border: none;
      outline: none;
    }
  }
}

.emailCheckIcon {
  font-size: 1.125rem;
  color: $darkerTeal;
}
