@import '../../../../css/global/index.scss';

.contentWrapper {
  margin: 2.5rem 0 3rem;
  .partnersList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    .partnersListItem {
      display: flex;
      border: 1px solid $lightGrey;
      align-content: center;
      border-radius: 4px;
      height: 54px;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        border-color: $darkTeal;
        background-color: $lighterTeal;
      }
      .checkboxWrapper {
        height: 100%;
        padding: 0 0.75rem 0 1.5rem;
        background-color: $lighterTeal;
        display: flex;
        align-items: center;
      }
      .content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        img {
          max-height: 65%;
          max-width: 90%;
          mix-blend-mode: multiply;
        }
      }
    }
  }
}