@import '../../../../../css/global/colors.scss';
@import '../../../../../css/mixins/responsiveness.scss';

.button {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  @include below(lg) {
    padding: 0 1rem;
  }
  background: none !important;
  border: 0;
  height: var(--headerHeight);
  color: $darkerGrey !important;
  transition: background-color var(--animationTransitionMs) ease-out !important;
  font-size: 1rem !important;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: rgba($darkerTeal, 0.1) !important;
    transition: background-color var(--animationTransitionMs) ease-in !important;
  }
  &:focus {
    outline: 0 !important;
  }
  .buttonIcon {
    transition: all var(--animationTransitionMs) ease-out;
    margin-left: 0.75rem;
    font-size: 1.25rem;
    color: $darkerTeal !important;
  }
  &.open {
    .buttonIcon {
      transform: rotate(180deg);
      transition: all 150ms ease-in;
    }
  }
}