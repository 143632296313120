@import '../../../../../css/global/colors.scss';

.notificationBellButton {
  position: relative;
  height: fit-content !important;
  align-items: center;
  padding: 0 1rem !important;
  background: none !important;
  border: 0;
  height: var(--headerHeight);
  color: $darkerGrey !important;
  transition: background-color var(--animationTransitionMs) ease-out;
  font-size: 1rem;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  .notificationBellIcon {
    transition: all var(--animationTransitionMs) ease-out;
    font-size: 1.5rem;
    color: $darkerTeal;
  }
  .notificationRedDot {
    position: absolute;
    top: -1px;
    right: 15px;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: $red;
    color: $white;
  }
}

.notificationUnorderedList {
  padding-inline-start: 0;
  margin-left: 0;
  margin-top: 1rem;
  .notificationListItem {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    user-select: none;
    background: none !important;
    &:hover {
      cursor: pointer;
      background-color: $lightTeal !important;
    }
    .dot {
      margin-right: 1rem;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $darkerTeal !important;
      flex-shrink: 0;
    }
    .content {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      max-height: 100px;
      p + p {
        margin-top: 0.25rem;
      }
    }
  }
  .unreadNotification {
    background-color: $lighterTeal !important;
  }
  p {
    line-height: 1.3;
  }
}

.tooltipWrapper {
  padding: 0;
  border: solid;
  border-color: $grey;
  border-width: 1px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  z-index: 101;
  .bottomLinkWrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 1rem 1rem 1rem;
    user-select: none;
    .readAllNotifications {
      font-size: 1rem;
      background: none !important;
      border: none !important;
      width: fit-content;
      text-decoration: underline;
      font-weight: bold;
      cursor: pointer;
      color: $darkerTeal;
      &:focus {
        outline: none;
      }
      &:hover {
        color: $darkTeal !important;
      }
    }
    .noNotifications {
      font-size: 1rem;
      background: none !important;
      margin-top: 1rem;
      border: none;
      padding: 1rem 0;
      cursor: pointer;
      color: $darkerTeal;
      &:focus {
        outline: none;
      }
      &:hover {
        color: $darkTeal !important;
      }
    }
  }
}