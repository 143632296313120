@import '../../../../../../../css/global/colors.scss';

.listWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  .list {
    position:fixed;
    width: 100%;
    height: calc(var(--headerExtendedHeight) - var(--headerHeight));
    box-sizing: border-box;
    .listContent {
      width: 100%;
      display: flex;
      justify-content: space-between;
      overflow: auto;
      overflow-x: hidden;
      max-height: 100%;
      height: auto;
      .category {
        margin-right: 2rem;
        min-width: 110px;
        .categoryTitle {
          width: 100%;
          font-size: 1rem;
          color: $darkerTeal;
          font-weight: bold;
          border-bottom: 1px solid rgba(0, 0, 0, .1);
          font-size: 1.125rem;
          padding-bottom: 1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          &.link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            border-radius: 5px 5px 0 0;
            text-decoration: none;
            .linkText {
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;
              width: 100%;
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
        .categoryItems {
          list-style: none !important;
          margin: 0 !important;
          padding: 0 !important;
          .categoryItem {
            width: 100%;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(0, 0, 0, .1);
            padding: 0.625rem 0 0.5rem;
            .categoryItemLink {
              width: 100%;
              font-size: 1rem;
              text-decoration: none;
              color: $darkerGrey;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;
              &:hover {
                cursor: pointer;
                color: $darkerTeal;
              }
            }
          }
        }
      }
    }
  }
}