@import '../../../../css/global/index.scss';

p {
  margin: 0;
}
.wrapper {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
  box-sizing: border-box;
  background-color: $whiteGrey;
  .title {
    font-size: 1.25rem;
    font-weight: $semibold;
    color: $darkerGrey;
    margin: 0;
  }
  .arrowWrapper {
    padding: 4px 12px;
    border-radius: 5px;
    background-color: $warmPink;
    cursor: pointer;
    .arrow {
      font-size: 1.5rem;
      color: $white;
    }
    .closeButton {
      cursor: pointer;
      background: none !important;
      color: black !important;
      border: 0 !important;
      padding: 0;
      &:hover {
        background: none !important;
        color: grey !important;
      }
      &:focus {
        outline: none;
      }
    }
  }
}