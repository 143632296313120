@import '../../../../../../css/global/colors.scss';

.wrapper {
  display: flex;
  height: var(--headerHeight);
  .loginButton,
  .signUpButton {
    background: none;
    border: 0;
    height: 100%;
    color: $darkerGrey;
    font-size: 1rem;
    border-radius: 0 !important;
    padding: 0;
    margin: 0;
    .buttonContent {
      padding: 0 1.25rem;
    }
  }
  .loginButton {
    transition: all 200ms ease-out;
    .buttonContent {
      box-shadow: 1px 0 0 0 $darkerGrey;
    }
    &:hover {
      transition: all 200ms ease-in;
      background-color: rgba($darkerTeal, 0.1);
    }
    &:focus {
      outline: none;
    }
    &:active {
      background-color: rgba($darkerTeal, 0.2);
    }
  }
  .signUpButton {
    color: $white;
    transition: all 200ms ease-out;
    padding: 0 1.5rem;
    .buttonContent {
      background-color: rgba($warmPink, 0.9);
      padding: 0.25rem 1.25rem;
      border-radius: 5px;
    }
    &:hover {
      background-color: rgba($warmPink, 0.9);
      transition: all 200ms ease-in;
      .buttonContent {
        background: none;
      }
    }
    &:focus {
      outline: none;
    }
    &:active {
      background-color: $warmPink;
      .buttonContent {
        background: none;
      }
    }
  }
}
