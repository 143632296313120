@import '../../css/global/colors.scss';
@import '../../css/global/typography.scss';

.label {
  position: relative;
  display: flex;
  margin-bottom: 0 !important;
  .customRadioWrapper {
    position: relative;
    width: 24px;
    min-width: 24px;
    height: 24px;
    .radio {
      position: absolute;
      z-index: 3;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 50%;
      border: 2px solid $black;
      background-color: transparent;
    }
    .ripple,
    .hoverBackground {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
    }
    .ripple {
      z-index: 2;
      top: -16px;
      bottom: -16px;
      left: -16px;
      right: -16px;
      background-color: $darkTeal;
      animation: ripple 0.2s ease-in;
    }
    .hoverBackground {
      z-index: 1;
      top: -8px;
      bottom: -8px;
      left: -8px;
      right: -8px;
    }
    &:hover,
    &.focused {
      cursor: pointer;
      .hoverBackground {
        opacity: 1;
        background-color: $lighterTeal;
      }
      .radio {
        background-color: $lighterTeal;
      }
    }
    &.checked {
      .radio {
        background-color: $darkTeal;
        border-color: $darkTeal;
        &:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-color: $lighterTeal;
        }
      }
      &:hover,
      &.focused {
        .radio {
          background-color: $darkTeal;
          border-color: $darkTeal;
        }
      }
    }
    &.error {
      .hoverBackground {
        background-color: $lighterRed;
      }
      .radio {
        background-color: transparent;
        border-color: $red;
      }
    }
    &.disabled {
      cursor: default;
      .radio {
        border-color: $grey;
      }
      &:hover {
        .hoverBackground {
          opacity: 0;
        }
        .radio {
          background-color: transparent;
        }
      }
      &.checked {
        .radio {
          background-color: $grey;
          border-color: $grey;
        }
      }
    }
  }
  .radioInput {
    -webkit-appearance: none;
    position: absolute;
    left: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    height: 0;
    width: 0;
  }
  .labelText {
    margin-left: 16px;
    position: relative;
    top: 3px;
    font-size: $fontMd;
    color: $black;
    line-height: 18px;
  }
}
.errorMessage {
  margin-top: 12px;
  font-size: $fontMd;
  color: $red;
}

@keyframes ripple {
  0% {
    opacity: 1;
    transform: scale(0, 0);
  }
  50% {
    transform: scale(.6, .6);
  }
  100% {
    opacity: 0;
    transform: scale(1, 1);
  }
}