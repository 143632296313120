@import '../../css/global/colors.scss';
@import '../../css/mixins/responsiveness.scss';

:root {
  --headerHeight: 48px;
  --headerExtendedHeight: 500px;
  --animationTransitionMs: 100ms;
}

.wrapper {
  position: fixed;
  z-index: 29;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  max-height: var(--headerHeight);
  background-color: $hintOfGreen;
  transition: max-height 150ms ease-out;
  overflow: hidden;
  z-index: 101;
  &.wpEnabled {
    top: 32px;
    @include below(xs) {
      top: 46px;
    }
  }
  @include below(md) {
    height: auto;
  }
  .contentCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--headerHeight);
    transition: background-color 150ms ease-out;
    .rightContent {
      display: flex;
      height: 100%;
    }
  }
  &.extended {
    max-height: var(--headerExtendedHeight);
    transition: max-height var(--animationTransitionMs) ease-in;
    &:after {
      content: '';
      position: absolute;
      top: var(--headerHeight);
      height: 1px;
      width: 100%;
      background: rgba(0, 0, 0, .1);
    }
    @include below(md) {
      max-height: 100vh;
    }
    .contentCenter {
      @include below(xs) {
        background-color: $darkerTeal;
        transition: background-color var(--animationTransitionMs) ease-in;
      }
    }
  }
}
.headerGap {
  height: var(--headerHeight);
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
}
